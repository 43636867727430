import React, { useState } from 'react';
import './App.css';
import UserInfoForm from './components/UserInfoForm';
import LetterGenerator from './components/LetterGenerator';
import StructuredData from './components/StructuredData';
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [userInfo, setUserInfo] = useState(null);

  const handleSubmit = (info) => {
    setUserInfo(info);
  };

  return (
    <div className="App">
      <StructuredData />
      <header className="App-header">
        <h1>📪时空信箱：对话过去的自己</h1>
      </header>
      <main>
        {!userInfo ? (
          <section className="user-info-section">
            <UserInfoForm onSubmit={handleSubmit} />
          </section>
        ) : (
          <section className="letter-section">
            <LetterGenerator userInfo={userInfo} />
          </section>
        )}
      </main>
      <footer className="app-footer">
        <div className="footer-content">
          <p className="copyright">© 2024 AISelf</p>
          <div className="social-links">
            <span className="social-text">交流使用反馈，获取产品更新：</span>
            <a 
              href="https://www.xiaohongshu.com/user/profile/5ba4bc246574cf0001d3321e" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="social-link"
            >
              🔖 小红书 @Richi
            </a>
            <a 
              href="https://okjk.co/Wd87CU" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="social-link"
            >
              ✨ 即刻 @Rik5
            </a>
          </div>
        </div>
      </footer>
      <Analytics />
    </div>
  );
}

export default App;